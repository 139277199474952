var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"loading":_vm.loading,"dense":"","disable-filtering":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.assessment-time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item['assessment-time']))+" ")]}},{key:"item.assessment-type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.assessmentLabels[item['assessment-type']])+" ")]}},{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"primary--text text--darken-1",attrs:{"href":"#"},domProps:{"textContent":_vm._s('Download')},on:{"click":function($event){return _vm.clickReport(item)}}})]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_c('pibot-risk-chip',{attrs:{"risk":item.severity,"color":_vm.getColor(item.severity),"small":"","tooltip":true}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('pibot-context-menu',{attrs:{"actions":_vm.getActions(item)},on:{"click":function (action) { return !!action.fn && action.fn(item); }}}),_c('pibot-pw-confirm',{ref:"pw-confirm",attrs:{"elevation":"0","color":"error darken-1","small":"","hide-activator":"","loading":_vm.loading,"disabled":_vm.loading},on:{"confirmed":function($event){return _vm.deleteItem(item)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Authenticate")]},proxy:true},{key:"text",fn:function(){return [_c('span',{staticClass:"error--text text--darken-1"},[_vm._v("Are you sure you want to permanently delete this assessment and all relevant data? This cannot be undone.")])]},proxy:true}],null,true)})]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No assessments available. ")]),_c('router-link',{staticClass:"primary--text text--darken-1",attrs:{"to":("incident/" + (_vm.item._id) + "/assessment/new")}},[_vm._v("Add a new assessment")]),_c('span',[_vm._v(" or ")]),_c('router-link',{staticClass:"error--text text--darken-1",attrs:{"to":("incident/" + (_vm.item._id) + "/delete")}},[_vm._v("Delete the incident")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }