<template>
  <v-card flat tile>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :loading="loading"
        dense
        disable-filtering
        disable-sort
        disable-pagination
        hide-default-footer
      >
        <!-- Created on -->
        <template #[`item.assessment-time`]="{ item }">
          {{ formatDate(item['assessment-time']) }}
        </template>

        <!-- Assessment type -->
        <template #[`item.assessment-type`]="{ item }">
          {{ assessmentLabels[item['assessment-type']] }}
        </template>

        <!-- Report -->
        <template #[`item.report`]="{ item }">
          <a href="#" v-text="'Download'" class="primary--text text--darken-1" @click="clickReport(item)"/>
        </template>

        <!-- Severity / Risk -->
        <template #[`item.severity`]="{ item }">
          <pibot-risk-chip :risk="item.severity" :color="getColor(item.severity)" small :tooltip="true"/>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <pibot-context-menu :actions="getActions(item)" @click="(action) => !!action.fn && action.fn(item)" />
            <pibot-pw-confirm
              ref="pw-confirm"
              elevation="0"
              color="error darken-1"
              small
              hide-activator
              :loading="loading"
              :disabled="loading"
              @confirmed="deleteItem(item)"
            >
              <template #title>Authenticate</template>
              <template #text><span class="error--text text--darken-1">Are you sure you want to permanently delete this assessment and all relevant data? This cannot be undone.</span></template>
            </pibot-pw-confirm>
        </template>

        <template #no-data>
          <span>No assessments available. </span>
          <router-link :to="`incident/${item._id}/assessment/new`" class="primary--text text--darken-1">Add a new assessment</router-link>
          <span> or </span>
          <router-link :to="`incident/${item._id}/delete`" class="error--text text--darken-1">Delete the incident</router-link>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'pibot-eprs-expand-item',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mixins: [eprs],
  components: {
    'pibot-risk-chip': () => import('@/components/chips/RiskChip'),
    'pibot-context-menu': () => import('@/components/buttons/ContextMenu'),
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm')
  },
  data () {
    return {
      options: {},
      headers: [
        { text: 'Created on', value: 'assessment-time', divider: true },
        { text: 'Assessment type', value: 'assessment-type', divider: true, cellClass: 'capitalize' },
        { text: 'Severity', value: 'severity', align: 'center', divider: true },
        { text: 'Report', value: 'report', align: 'center', divider: true },
        { text: 'Actions', value: 'actions', align: 'center' }
      ],
      assessmentLabels: {
        'dropped object': 'Dropped Object',
        dent: 'Dent-Gouge Combination',
        fatigue: 'Fatigue Life After Dent-Gouge Combination'
      }
    }
  },
  computed: {
    ...mapState({
      reportMeta: state => state.eprs.reportMeta
    }),
    items () {
      const assessmentItems = this.item.assessments.map((a) => Object.assign({ aid: a.assessment_id }, { 'assessment-type': a.assessment_type }, ...a.data.map(({ key, value }) => ({ [key]: value }))))
      assessmentItems.sort((a, b) => b['assessment-time'] - a['assessment-time'])
      return assessmentItems
    }
  },
  methods: {
    ...mapActions({
      fetchReport: 'eprs/socket_fetchReport',
      delete: 'eprs/socket_deleteAssessment',
      deleteReport: 'eprs/socket_deleteReport'
    }),
    getActions (item) {
      const actions = [
        {
          label: 'Details',
          icon: 'find_in_page',
          fn: (item) => this.inspect(item)
        }
      ]

      // Only the most recent assessment can be used for a follow-up
      if (Number(item.aid) === this.items[0].aid) {
        actions.push({
          label: 'Add follow-up assessment',
          icon: 'addchart',
          fn: (item) => this.next(item)
        })
      }

      actions.push({
        label: 'Edit and re-evaluate',
        icon: 'edit',
        fn: (item) => this.edit(item)
      })

      actions.push({ divider: true })

      actions.push({
        label: 'Permantly delete',
        icon: 'delete_forever',
        fn: () => this.pwConfirm()
      })

      return actions
    },
    clickReport (item) {
      const incidentId = this.item._id
      const aid = item.aid
      const element = this.reportMeta.filter((d) => d.incident_id === incidentId && parseInt(d.assessment_id) === aid)
      if (!element || element.length === 0) return // some sort of warning or popup maybe saying no report has been found
      const timestamps = element.map(d => parseInt(d.timestamp))
      const timestamp = Math.max(...timestamps)
      this.fetchReport(timestamp)
        .then(res => {
          const blob = new Blob([res.data])
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'EPRS_' + item['assessment-type'] + '_' + timestamp + '.pdf'
          link.click()
        })
    },
    inspect (item) {
      this.$router.push(`incident/${this.item._id}/assessment/${item.aid}`)
    },
    edit (item) {
      this.$router.push(`incident/${this.item._id}/assessment/edit/${item.aid}`)
    },
    next (item) {
      this.$router.push(`incident/${this.item._id}/assessment/follow-up/${item.aid}`)
    },
    pwConfirm () {
      this.$refs['pw-confirm'].dialog = true
    },
    async deleteItem (assessment) {
      const payload = {
        incident_id: this.item._id,
        assessment_id: assessment.aid,
        update_geojson: true
      }

      const report = this.reportMeta.find((d) => parseInt(d.assessment_id) === assessment.aid && d.incident_id === this.item._id)

      await Promise.all([this.delete(payload), this.deleteReport(report.timestamp)])
        .finally(() => {
          this.$router.go()
        })
    }
  }
}
</script>
